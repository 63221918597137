<template>
  <div>
    <r-page-modal
      :prompt-on-leave="false"
      prompt-mesage="Are you sure?"
      v-if="isModalOpen"
      :is-modal="true"
      @hide="closeModal"
      key="modalDeleteKey"
      remove-scrolling
      class="warning-modal"
    >
      <template #page-content>
        <div class="columns is-multiline">
          <div class="column is-12">
            <img src="../../public/images/yellow-info.svg" class="center" />
          </div>
          <div class="column is-12 warning-modal__title">{{ title }}</div>
          <div class="column is-12 warning-modal__subtitle">{{ subtitle }}</div>
          <div class="column is-6">
            <b-button type="is-primary" expanded @click.native="closeModal">
              No
            </b-button>
          </div>
          <div class="column is-6">
            <b-button
              type="is-primary"
              outlined
              expanded
              @click.native="runMethods"
            >
              Yes
            </b-button>
          </div>
        </div>
      </template>
    </r-page-modal>
  </div>
</template>
<script>
export default {
  props: {
    isModalOpen: {
      default: false,
      type: Boolean,
    },
    title: {
      default: '',
      type: String,
    },
    subtitle: {
      default: '',
      type: String,
    },
  },

  methods: {
    closeModal() {
      this.$emit('close')
    },
    runMethods(event) {
      this.$emit('run', event)
    },
  },
}
</script>
