<template>
  <r-page page-title="Voucher">
    <template #page-header>
      <h2 class="page-title">Voucher</h2>
      <r-menu-tab :level="2" />
    </template>
    <template #action-bar>
      <div class="level">
        <div class="level-left">
          <b-button
            type="is-primary"
            icon-left="plus"
            label="Add Voucher"
            tag="router-link"
            to="/voucher/add-voucher"
          ></b-button>
        </div>
      </div>
    </template>
    <template #page-content>
      <div
        id="table-request-employment"
        class="table-timeoff-type table-request-personal"
      >
        <b-table
          :data="data ? data.data : []"
          :per-page="perPage"
          :current-page.sync="page"
          :loading="isVoucherLoading"
          ref:table
          hoverable
          paginated
          backend-pagination
          backend-sorting
          :total="total ? total : 0"
          :default-sort-direction="sortOrder"
          :default-sort="[sortField, sortOrder]"
          class="table-voucher-management"
          @sort="onSort"
          @page-change="onPageChange"
          :sticky-header="stickyHeaders"
          aria-next-label="Next page"
          aria-previous-label="Previous page"
          aria-page-label="Page"
          aria-current-label="Current page"
        >
          <template>
            <b-table-column
              v-slot="props"
              field="id"
              label="No."
              sortable
              width="5%"
            >
              <span>
                {{ props.index + from }}
              </span>
            </b-table-column>
            <b-table-column
              v-slot="props"
              field="name"
              label="Voucher Name"
              width="15%"
            >
              <span>
                {{ props.row.name }}
              </span>
            </b-table-column>
            <b-table-column
              v-slot="props"
              field="code"
              label="Voucher Code"
              width="10%"
            >
              <span>
                {{ props.row.code }}
              </span>
            </b-table-column>
            <b-table-column
              v-slot="props"
              field="discount"
              label="Discount"
              width="10%"
            >
              <span>
                {{ props.row.discount }}
              </span>
            </b-table-column>
            <b-table-column
              v-slot="props"
              field="valid_from"
              label="Valid From"
              sortable
              width="10%"
            >
              <span>
                {{ formatDate(props.row.validFrom) }}
              </span>
            </b-table-column>
            <b-table-column
              v-slot="props"
              field="valid_to"
              label="Valid To"
              sortable
              width="10%"
            >
              <span>
                {{ formatDate(props.row.validTo) }}
              </span>
            </b-table-column>
            <b-table-column
              v-slot="props"
              field="description"
              label="Description"
              width="30%"
            >
              <span>
                {{ props.row.description }}
              </span>
            </b-table-column>
            <b-table-column
              field="action"
              label="Action"
              v-slot="props"
              width="5%"
            >
              <b-icon
                icon="delete"
                class="pointer-click"
                custom-size="mdi-18px"
                @click.native="openDelete(props.row.id)"
              ></b-icon>
              <b-icon
                icon="pencil"
                custom-size="mdi-18px"
                class="click"
                @click.native="openEditPage(props.row.id)"
              ></b-icon>
            </b-table-column>
          </template>
          <template slot="empty">
            <section class="section">
              <div class="content has-text-grey has-text-centered">
                No data yet, please contact us if you have any problems
                (hello@getrise.id)
              </div>
            </section>
          </template>
        </b-table>
      </div>
      <WarningModal
        :isModalOpen="isDeleteOpen"
        :title="'Are you sure?'"
        :subtitle="'are you sure you want to delete this voucher?'"
        @close="closeModalDelete"
        @run="deleteVoucher(selected)"
      ></WarningModal>
    </template>
  </r-page>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from 'vuex'
import { showToast } from '@/services/util'
import moment from 'moment-timezone'
import WarningModal from '../../../components/WarningModal.vue'
export default {
  components: { WarningModal },
  data() {
    return {
      data: null,
      isVoucherLoading: false,

      perPage: 10,
      page: 1,
      lastPage: 1,
      total: 0,
      isPaginated: true,
      stickyHeaders: true,
      defaultSortOrder: 'asc',
      sortField: 'id',
      sortOrder: 'desc',

      selected: 0,

      isDeleteOpen: false,
    }
  },

  async mounted() {
    this.setVoucher([])
    await this.loadVoucherData()
  },

  computed: {
    ...mapGetters({
      getVoucher: 'voucher/getVoucherData',
    }),
  },

  methods: {
    ...mapActions({
      actionLoadVoucherData: 'voucher/fetchVoucherData',
      purgeVoucher: 'voucher/deleteVoucher',
    }),

    ...mapMutations({
      setVoucher: 'voucher/setVoucherData',
    }),

    async onSort(field, order) {
      this.sortField = field
      this.sortOrder = order
      this.setVoucher([])
      await this.loadVoucherData()
    },

    onPageChange(page) {
      this.page = page
      this.loadVoucherData()
    },

    async loadVoucherData() {
      try {
        const response = await this.actionLoadVoucherData({
          perPage: this.perPage,
          page: this.page,
          sortField: this.sortField,
          sortOrder: this.sortOrder,
        })
        if (response && response.status === 200) {
          this.data = response.data
        }
        this.lastPage = response.data.meta.lastPage
        this.total = response.data.meta.total
        this.from = response.data.meta.from
      } catch (e) {
        showToast(e.response.data.message, 'is-danger', 'is-top')
      }
    },

    openEditPage(id) {
      this.$router.push(`/voucher/edit-voucher/${id}`)
    },

    formatDate(str) {
      if (str) {
        return moment(str).format('DD MMMM YYYY')
      }
    },

    openDelete(id) {
      this.selected = id
      this.isDeleteOpen = true
    },

    closeModalDelete() {
      this.selected = 0
      this.isDeleteOpen = false
    },

    async deleteVoucher(id) {
      try {
        await this.purgeVoucher(id)
        this.closeModalDelete()
        this.$swal({
          icon: 'success',
          titleText: 'Voucher deleted!',
          confirmButtonText: 'Done',
          customClass: {
            confirmButton: 'button is-success',
          },
        })
        this.setVoucher([])
        await this.loadVoucherData()
      } catch (e) {
        this.closeModalDelete()
        this.$swal({
          icon: 'error',
          titleText: 'Failed to delete voucher!',
          text: e.response.message,
          confirmButtonText: 'Ok',
          customClass: {
            confirmButton: 'button is-primary',
          },
        })
      }
    },
  },
}
</script>
